html {
  scroll-behavior: smooth;
}

/* Aba ter os itens visíveis em telas pequenas */
.cardAnexo label {
  white-space: normal;
}

thead {
  background-color: #fafafa;
}

.scroller {
  white-space: normal!important;
}

.scroller>div {
  display: inline-block;
}

.innerModal {
  z-index: 1100 !important;
}

.fabButton {
  z-index: 3;
}

.inputRequired::after {
  content: '*';
}

.inputSelecao .inputFeedback {
  right: 24px !important;
}

.clickable {
  cursor: pointer;
  color: #3060a5;
  text-decoration: none;
}

.clickable:hover {
  color: darkblue;
  text-decoration: none;
}

svg.clickable {
  fill: #3060a5;
}

svg.clickable:hover {
  text-decoration: none;
  fill: darkblue;
}

svg.empty {
  fill: darkgray;
}

.GridContainer {
  width: 100% !important;
}

.notificacao {
  display: inline-block;
  position: absolute;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 16px;
  width: 16px;
  height: 16px;
  font-size: .7em;
  line-height: 16px;
  background-color: red;
  color: white;
  border: 1px solid white;
  text-align: center;
}

.smallButton {
  width: auto !important;
  height: auto !important;
  min-width: auto !important;
  min-height: auto !important;
  padding: 4px !important;
  margin: 5px !important;
}

.floatIcon {
  right: 15px !important;
  position: absolute !important;
  margin-top: 0px !important;
}

.datePicker button {
  padding: 2px
}

.linksTopo .documentoIcon {
  border: 1px solid gray;
  border-radius: 50%;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  margin-top: -10px;
  margin-left: 10px;
}

.errorPage {
  margin: 50px;
}