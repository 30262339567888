.documentoViewer {
    padding: 5px;
    height: calc(100% - 10px);
}

.documentoViewer:hover {
    background-color: #fafafa;
}

.documentoIcon {
    max-width: 50px;
    max-height: 50px;
    vertical-align: middle;
    margin: 5px 15px 5px 0;
}