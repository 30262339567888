@media print {
  * {
    box-shadow: none !important;
  }
  body, .MainLayout-wrapper-1, #root {
    overflow: visible !important;
    height: auto !important;
  }
  .mainPanel {
    overflow: visible !important;
    height: auto !important;
    background-color: white;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
  }
  .mainContent {
    margin: 0 !important;
    padding: 0 !important;
  }
  .mainContent button {
    display: none !important;
  }
  svg {
    page-break-inside: avoid !important;
  }
  .Card .Card {
    border: none !important;
  }
  header.TopoAppBar {
    border: none !important;
    width: 100%;
    position: relative;
  }
  .hidePrint {
    display: none !important;
  }
}